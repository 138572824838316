.subtitle {
    font-weight: 700;
    font-size: 3rem;
    line-height: 4.5rem;
    margin-bottom: 24px;
    text-align: center;
    color: #1F1F1F;
    @media (max-width: 480px) {
        font-size: 2rem;
        line-height: 3rem; } }
