.logo {
    width: 154px;
    img {
        width: 100%; }
    @media (max-width: 900px) {
        width: 194px; } }
nav {
    border-bottom: 1px solid #E6EDF0; }
.nav {
    padding: 32px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    &__start {
        display: flex;
        align-items: center;
        img {
            display: block; } }
    &__end {
        display: flex;
        align-items: center; } }

.button_nav {
    margin: 0;
    margin-right: 28px;
    width: 95px;
    height: 48px;
    font-size: 1rem;
    @media (max-width: 480px) {
        width: 74px;
        height: 44px;
        margin-right: 16px; } }

@media screen and (max-width: 480px) {
    .lang__arrow {
        margin-left: 3px; }
    .nav {
        &__end {
            font-size: .85rem; } }
    .logo {
        width: 135px; } }

@media screen and (max-width: 320px) {
    .nav {
        flex-direction: column;
        &__start {
            margin-bottom: 12px; } } }

