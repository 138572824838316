.service {
    text-align: left;
    display: flex;
    &__icon {
        margin-right: 24px; }
    &__title {
        font-weight: 600;
        font-size: 1.25rem;
        line-height: 1.9rem;
        margin-bottom: 8px; }
    &__description {
        color: #70777A; } }

.service:not(:last-child) {
    margin-bottom: 48px; }
