@import url('https://fonts.googleapis.com/css2?family=Inter:wght@200;300;400;500;600;700&display=swap');
* {
  box-sizing: border-box; }
body, h1, h2, h3, h4, h5, h6, p, ul, li {
  margin: 0;
  padding: 0; }
html {
  font-size: 16px;
  @media (max-width: 1130px) {
    font-size: 14px; } }
body {
  font-family: 'Inter', sans-serif;
  font-weight: 400;
  color: #1F1F1F;
  font-size: 1.125rem;
  line-height: 1.55rem; }
.wrap {
  max-width: 1360px;
  padding: 0 35px;
  margin: 0 auto; }
a {
  text-decoration: none;
  color: inherit; }
button {
  border: none;
  font-size: inherit; }
input {
  font-size: inherit;
  line-height: inherit; }
@media screen and (max-width: 768px) {
  .wrap {
    padding: 0 48px; } }

@media screen and (max-width: 660px) {
  html {
    font-size: 14px; }
  .wrap {
    padding: 0 24px; } }

@media screen and (max-width: 330px) {
    html {
        font-size: 12px; } }
