.menu {
    display: flex;
    margin-left: 50px;
    @media (max-width: 1130px) {
        margin: 0 20px; }
    .menu__item {
        margin: 0 14px;
        transition: .2s linear;
        &:hover {
            color: #007DFF; }
        @media (max-width: 1130px) {
            margin: 0 6px; } } }
.log-in {
    width: 94px;
    height: 48px;
    border: 1px solid #007DFF;
    border-radius: 12px;
    background-color: #fff;
    color: #007DFF;
    font-size: 1rem;
    cursor: pointer;
    margin-right: 28px; }

@media screen and (max-width: 900px) {
    .menu {
        display: none; } }

@media screen and (max-width: 480px) {
    .log-in {
        width: 60px;
        height: 32px;
        margin-right: 15px;
        border-radius: 8px;
        font-size: .85rem; } }

