.faq {
    max-width: 1080px;
    margin: 0 auto 168px;
    padding: 120px 48px 0;
    &__title {
        font-weight: 700;
        font-size: 3.5rem;
        line-height: 4.25rem;
        padding-left: 24px;
        margin-bottom: 32px; } }

@media screen and ( max-width: 480px) {
    .faq {
        padding: 90px 0 0;
        &__title {
            font-size: 3rem;
            line-height: 3.8rem; } }
    .css-o4b71y-MuiAccordionSummary-content {
        font-size: 1.28rem; }
    .accordion__wrap {
        font-size: 1rem;
        line-height: 1.4rem; } }
