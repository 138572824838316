.steps {
    margin-top: 200px;
    @media (max-width: 480px) {
        margin-top: 70px; }
    &__title {
        max-width: 704px;
        margin: 0 auto;
        text-align: center; }
    &__items {
        max-width: 860px;
        margin: 51px auto 0; }
    &__item {
        display: flex;
        margin-bottom: 48px;
        @media (max-width: 480px) {
            margin-bottom: 24px; } }
    &__circle {
        width: 48px;
        height: 48px;
        background: #007DFF;
        border-radius: 24px;
        flex-shrink: 0;
        color: #fff;
        font-weight: 500;
        font-size: 1.5rem;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 24px;
        @media (max-width: 480px) {
            width: 36px;
            height: 36px;
            margin-right: 16px; } }
    &__subtitle {
        font-weight: 600;
        font-size: 1.25rem;
        line-height: 1.87rem;
        margin-bottom: 8px;
        color: #1F1F1F; }
    &__desc {
        font-size: 1.12rem;
        line-height: 1.75rem;
        line-height: 28px;
        color: #70777A; } }
