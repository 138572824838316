.news {
    margin-top: 200px;
    @media (max-width: 480px) {
        margin-top: 70px; }
    &__title {
        margin-bottom: 50px;
        @media (max-width: 900px) {
            margin-bottom: 35px; } }
    &__items {
        display: flex;
        justify-content: space-between;
        @media (max-width: 550px) {
            flex-direction: column;
            align-items: center; } }
    &__item {
        width: 417px;
        @media (max-width: 550px) {
            width: 100%; }
        &:nth-child(2) {
            margin: 0 15px;
            @media (max-width: 550px) {
                margin-top: 57px; } }
        img {
            display: block;
            width: 100%;
            border-radius: 8px; }
        @media (max-width: 900px) {
            &:nth-child(3) {
                display: none; } } }
    &__image {
        margin-bottom: 12px; }
    &__details {
        margin-bottom: 8px;
        color:  #007DFF;
        font-size: 1rem;
        line-height: 1.5rem;
        display: flex; }
    &__date {
        margin-right: 16px; }
    &__subtitle {
        font-weight: 600;
        font-size: 1.5rem;
        line-height: 2.2rem;
        color: #1F1F1F;
        margin-bottom: 4px; }
    &__desc {
        font-size: 1.2rem;
        line-height: 1.8rem;
        color: #70777A; } }
