.description {
    &__wrap {
        max-width: 714px;
        margin: 0 auto; }
    position: relative;
    &__title {
        margin: 50px 0 24px;
        font-weight: 700;
        font-size: 8rem;
        line-height: 8.5rem;
        font-weight: 700;
        display: flex;
        justify-content: center;
        span {
            font-weight: 300; }
        @media (max-width: 900px) {
            font-size: 6rem;
            line-height: 7rem;
            margin: 40px 0 20px; }
        @media (max-width: 480px) {
            font-size: 3.5rem;
            line-height: 4.5rem;
            margin: 0px 0 10px; } } }
@media screen and (max-width: 480px) {
    .header {
        .description {
            &__title {
                font-size: 4rem;
                line-height: 5rem; } } }
    .description {
        &__text {
            font-size: 1rem;
            line-height: 1.4rem; }
        &__count {
            font-size: 1rem; } } }

