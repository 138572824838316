footer {
    border-top: 1px solid #E6EDF0;
    padding-top: 56px; }
.footer {
    display: grid;
    padding-bottom: 20px;
    justify-content: space-between;
    width: 100%;
    grid-template-areas: "a b c d";
    &__title {
        font-weight: 500;
        font-size: 1.25rem;
        line-height: 1.9rem;
        margin-bottom: 24px; }
    &__link {
        color: #70777A;
        margin-bottom: 16px; }
    &__logo {
        width: 153px;
        margin-top: 7px;
        margin-bottom: 32px; }
    &__item {
        &:nth-child(1) {
            grid-area: a; }
        &:nth-child(2) {
            grid-area: a; }
        &:nth-child(3) {
            grid-area: b; }
        &:nth-child(4) {
            grid-area: c; }
        &:nth-child(5) {
            grid-area: d; } } }
.networks {
    display: flex;
    width: 100%;
    margin-top: 95px;
    &__item {
        &:nth-child(2) {
            margin: 0 16px; }
        cursor: pointer;
        border: 1px solid #E6EDF0;
        width: 40px;
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 8px; } }

@media screen and (max-width: 768px) {
    .footer {
        grid-template-areas: "a c d";
        &__item {
            &:nth-child(3) {
                display: none; } } } }

@media screen and (max-width: 550px) {
    .footer {
        grid-template-areas: "a e" "c d" "c d";
        &__item {
            &:nth-child(2) {
                grid-area: e; }
            &:nth-child(1) {
                display: flex;
                width: 100%;
                justify-content: space-between;
                align-items: center;
                margin-bottom: 38px;
                img {
                    margin: 0; } } }
        &__item {
            width: 100%; } }
    .networks {
        margin: 0;
        width: auto; } }

@media screen and (max-width: 550px) {
    .footer {
        font-size: 1rem;
        &__item {
            &:nth-child(1) {
                margin-bottom: 24px; } }
        &__title {
            font-size: 1.25rem;
            margin-bottom: 16px; } }
    .networks {
        margin-top: -7px;
        &__item {
            width: 36px;
            height: 36px; } } }

@media screen and (max-width: 350px) {
    footer {
        .wrap {
            padding: 0 16px; } }
    .networks {
        &__item {
            width: 32px;
            height: 32px;
            &:nth-child(3) {
                img {
                    margin-top: -3px; } }
            img {
                width: 12px; }
            &:nth-child(2) {
                margin: 0 4px; } } } }

@media screen and (max-width: 330px) {
    .footer {
        &__item:nth-child(1) {
            flex-direction: column;
            .footer__logo {
                margin-bottom: 15px; } } } }

@media screen and (max-width: 310px) {
    .footer {
        grid-template-areas: "a a" "e e" "c d" "c d";
        &__item {
            &:nth-child(1) {
                margin: 0; }
            &:last-child {
                text-align: right; } } }
    .networks {
        margin: 0 auto 20px; } }


