.services {
    text-align: center;
    margin: 200px 0 0px;
    &__content {
        display: flex;
        justify-content: center;
        margin: 70px 0 0;
        width: 100%;
        padding: 0 0 0 66px; }
    &__box {
        width: 100%;
        height: 90%;
        bottom: 0;
        background: #E6F2FF;
        border-radius: 24px;
        position: absolute;
        z-index: -1; }
    &__left {
        margin-right: 70px;
        position: relative;
        width: 520px;
        @media (max-width:700px) {
            width: calc(100% - 80px);
            width: 253px; } }
    &__right {
        width: 500px;
        @media (max-width:700px) {
            width: 100%; } }
    &__img {
        width: 100%;
        flex-shrink: 0;
        filter: drop-shadow(0px 2px 10px rgba(195, 208, 222, 0.4));
        border-radius: 12px;
        img {
            width: 100%;
            display: block; } } }


.sms {
    width: 279px;
    position: absolute;
    top: 0;
    z-index: 2;
    @media (max-width: 500px) {
        width: 150px;
        height: 30px; }
    &_1 {
        top: 40%;
        left: -60px;
        @media (max-width: 500px) {
            top: 35%;
            left: -20px; } }
    &_2 {
        top: 45%;
        right: -60px;
        @media (max-width: 500px) {
            right: -20px; } }
    &_3 {
        top: 70%;
        left: 18px; }
    &_4 {
        top: 82%;
        right: 11px; } }
.description2__wrap {
    font-size: 1.25rem;
    line-height: 1.9rem;
    max-width: 900px;
    margin: 0 auto; }
@media screen and (max-width: 1000px) {
    .services {
        padding: 48px 0;
        &__content {
            flex-direction: column;
            align-items: center;
            padding: 0; }
        &__img {
            margin-right: 0; }
        &__right {
            max-width: 750px;
            margin-top: 48px; }
        &__left {
            margin: 0; } } }

@media screen and (max-width: 480px) {
    .services {
        background-color: #fff;
        margin-top: 70px;
        padding: 0;
        &__content {
            margin-top: 48px; }
        &__right {
            margin-top: 32px;
            font-size: 1.3rem;
            line-height: 2rem; } }
    .service {
        margin-bottom: 24px;
        &__icon {
            img {
                width: 36px; } }
        &__text {
            font-size: 1rem;
            line-height: 1.4rem; } } }
