.module {
    &__title {
        font-size: 2.7rem;
        line-height: 3.4rem;
        text-align: center;
        margin: 80px auto 32px;
        font-weight: 700;
        max-width: 783px;
        span {
            color: rgba(0, 125, 255, 1); }
        @media (max-width: 500px) {
            font-size: 2rem;
            line-height: 3rem;
            margin: 40px auto 24px; } }
    &__desc {
        max-width: 471px;
        font-size: 1.2rem;
        line-height: 2rem;
        text-align: center;
        color: #1F1F1F;
        margin: 0 auto 48px;
        @media (max-width: 500px) {
            font-size: 1.1rem;
            line-height: 1.6rem;
            margin: 0 auto 24px; } }
    .news__items {
        margin-bottom: 32px; } }
.filters {
    display: flex;
    justify-content: center;
    margin-bottom: 50px;
    flex-wrap: wrap;
    @media (max-width: 500px) {
        margin-bottom: 30px; } }
.filter {
    margin: 0 10px 20px;
    padding: 7px 24px;
    border: 1px solid #007DFF;
    border-radius: 41px;
    font-size: 1.2rem;
    text-align: center;
    color: #007DFF;
    transition: .3s;
    @media (max-width: 500px) {
        font-size: 1rem;
        margin: 0 5px 10px;
        padding: 5px 16px; }
    &:hover {
        color: #fff;
        background: #007DFF; } }

.more {
    border: 1px solid #007DFF;
    color: #007DFF;
    padding: 16px 32px;
    background: #fff;
    border-radius: 12px;
    margin: 48px auto 200px;
    display: block;
    transition: .3s;
    cursor: pointer;
    @media (max-width: 500px) {
        margin: 48px auto 70px; }
    &:hover {
        color: #fff;
        background: #007DFF; } }
